import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  query,
  where,
  getDoc,
  getDocs
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDOcScYWO8GT1RWkLCIHB0S2CMhV6pmHGE",
  authDomain: "qrtimeline.firebaseapp.com",
  projectId: "qrtimeline",
  storageBucket: "qrtimeline.appspot.com",
  messagingSenderId: "988826248002",
  appId: "1:988826248002:web:a5892fd1ce200506101edb",
  measurementId: "G-N1ZSF9X8V2"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Fonction pour ajouter un objet directement dans Firestore
export const addObject = async (objectData) => {
  console.log('Attempting to add object:', objectData);
  try {
    // Créer un nouvel objet dans la collection Firestore
    const docRef = await addDoc(collection(db, 'objects'), {
      ...objectData,
      createdAt: new Date().toISOString(),
    });

    const objectId = docRef.id;
    const timelineUrl = `https://drap.eternity-design.fr/timeline/${objectId}`;
    
    console.log('Timeline URL:', timelineUrl);

    // Retourner l'URL pour que le frontend puisse générer le QR code
    return { timelineUrl };
  } catch (error) {
    console.error('Erreur lors de l\'ajout de l\'objet:', error);
    throw error;
  }
};

// Fonction pour récupérer un objet depuis Firestore
export const getObject = async (objectId) => {
  console.log('Attempting to get object with ID:', objectId);
  try {
    const docRef = doc(db, 'objects', objectId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Object found:', docSnap.data());
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such object!");
      return null;
    }
  } catch (error) {
    console.error("Error getting object:", error);
    throw error;
  }
};

// Fonction pour récupérer les photos associées à un objet depuis Firestore
export const getPhotos = async (objectId) => {
  console.log('Attempting to get photos for object ID:', objectId);
  try {
    const q = query(collection(db, 'photos'), where('objectId', '==', objectId));
    const querySnapshot = await getDocs(q);
    const photos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log('Photos retrieved:', photos.length);
    return photos;
  } catch (error) {
    console.error("Error getting photos:", error);
    throw error;
  }
};

// Fonction pour ajouter une photo à Firestore
export const addPhoto = async (objectId, photoData) => {
  console.log('Attempting to add photo for object ID:', objectId);
  try {
    const docRef = await addDoc(collection(db, 'photos'), {
      objectId,
      ...photoData,
      date: new Date().toISOString()
    });
    console.log('Photo added successfully with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding photo:", error);
    throw error;
  }
};

// Fonction pour uploader une photo dans Firebase Storage
export const uploadPhoto = async (file) => {
  console.log('Uploading photo:', file.name);
  const storageRef = ref(storage, `photos/${file.name}`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Photo uploaded, download URL:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading photo:", error);
    throw error;
  }
};

export { db, analytics };
