import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'react-qr-scanner';
import { QRCodeSVG } from 'qrcode.react';
import { motion, AnimatePresence } from 'framer-motion';
import { addObject, uploadPhoto } from '../services/firebase';
import { FiPackage, FiCalendar, FiMapPin, FiPlus, FiCamera, FiEye, FiSearch, FiX } from 'react-icons/fi';
import { FaCar } from 'react-icons/fa'; // Remplacement de l'icône de camion par une voiture
import PropTypes from 'prop-types';

const TypeButton = ({ type, icon, selected, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={() => onClick(type)}
    className={`flex flex-col items-center p-6 m-2 rounded-lg ${
      selected ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-300'
    } hover:bg-blue-400 transition-colors shadow-lg`}
  >
    {icon}
    <span className="mt-2 font-semibold">{type}</span>
  </motion.button>
);

TypeButton.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const HomePage = () => {
  const [objectType, setObjectType] = useState('');
  const [objectName, setObjectName] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [qrCode, setQrCode] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState('');
  const [isCreatingTimeline, setIsCreatingTimeline] = useState(false);
  const [manualCode, setManualCode] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [scanError, setScanError] = useState('');
  const navigate = useNavigate();

  const handleTypeSelect = (type) => {
    setObjectType(type);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPhotoPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!objectType || !objectName || !photo) {
      setError('Veuillez remplir tous les champs et sélectionner une photo.');
      return;
    }

    setIsCreating(true);
    setError('');
    try {
      const photoURL = await uploadPhoto(photo);

      // Créer l'objet et récupérer l'URL avec le token JWT depuis le backend
      const newObject = {
        type: objectType,
        name: objectName,
        photo: photoURL,
      };

      const response = await addObject(newObject); // addObject doit retourner l'URL avec le token
      const { timelineUrl } = response;  // Récupérer l'URL contenant le token

      // Afficher l'URL avec le token sous forme de QR code
      setQrCode(timelineUrl);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'objet:', error);
      setError('Une erreur est survenue lors de la création de l&apos;objet. Veuillez réessayer.');
    } finally {
      setIsCreating(false);
    }
  };

  const handleViewTimeline = () => {
    if (qrCode) {
      const objectId = qrCode.split('/').pop();
      navigate(`/timeline/${objectId}`);
    }
  };

  const handleScan = (data) => {
    if (data) {
      navigate(`/timeline/${data.text}`);
    }
  };

  const handleScanError = (err) => {
    console.error(err);
    setScanError('Erreur lors du scan. Veuillez réessayer.');
  };

  const handleSearch = () => {
    if (manualCode) {
      navigate(`/timeline/${manualCode}`);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white p-8"
    >
      <motion.h1
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        className="text-5xl font-bold mb-12 text-center text-blue-300"
      >
        Créez votre Timeline
      </motion.h1>

      {/* Bouton Scanner un QR Code */}
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsScanning(!isScanning)} // Toggle scanner on click
        className="mx-auto block bg-green-500 text-white py-3 px-6 rounded-lg font-semibold text-lg shadow-lg hover:bg-green-600 transition-colors"
      >
        {isScanning ? <FiX className="inline mr-2" /> : <FiSearch className="inline mr-2" />} Scanner un QR Code
      </motion.button>

      {/* Scanner QR Code */}
      <AnimatePresence>
        {isScanning && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="mt-8 mx-auto bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg"
          >
            <QrScanner
              delay={300}
              onError={handleScanError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
            {scanError && <p className="text-red-500 mt-4">{scanError}</p>}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Champ de recherche pour entrer un code manuellement */}
      <motion.div className="mt-6 flex items-center justify-center">
        <input
          type="text"
          placeholder="Entrez un code QR manuellement"
          value={manualCode}
          onChange={(e) => setManualCode(e.target.value)}
          className="w-80 p-3 bg-gray-700 rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleSearch}
          className="ml-4 bg-blue-500 text-white py-3 px-4 rounded-lg font-semibold shadow-lg hover:bg-blue-600 transition-colors"
        >
          <FiSearch />
        </motion.button>
      </motion.div>

      {/* Bouton Créer sa Timeline */}
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsCreatingTimeline(!isCreatingTimeline)}
        className="mt-12 mx-auto block bg-blue-500 text-white py-3 px-6 rounded-lg font-semibold text-lg shadow-lg hover:bg-blue-600 transition-colors"
      >
        {isCreatingTimeline ? <FiX className="inline mr-2" /> : <FiPlus className="inline mr-2" />} Créer sa Timeline
      </motion.button>

      {/* Formulaire de création de timeline */}
      <AnimatePresence>
        {isCreatingTimeline && (
          <motion.form
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            onSubmit={handleSubmit}
            className="mt-8 p-6 bg-gray-800 rounded-lg shadow-lg"
          >
            <div className="mb-8">
              <h2 className="text-2xl mb-4 font-semibold text-blue-300">Type d&apos;objet</h2>
              <div className="flex flex-wrap justify-center">
                <TypeButton type="Objet" icon={<FiPackage size={32} />} selected={objectType === 'Objet'} onClick={handleTypeSelect} />
                <TypeButton type="Voiture" icon={<FaCar size={32} />} selected={objectType === 'Voiture'} onClick={handleTypeSelect} />
                <TypeButton type="Événement" icon={<FiCalendar size={32} />} selected={objectType === 'Événement'} onClick={handleTypeSelect} />
                <TypeButton type="Lieux" icon={<FiMapPin size={32} />} selected={objectType === 'Lieux'} onClick={handleTypeSelect} />
              </div>
            </div>

            <motion.div className="mb-6" whileHover={{ scale: 1.02 }}>
              <label className="block mb-2 text-blue-300">Nom de l&apos;objet</label>
              <input
                type="text"
                value={objectName}
                onChange={(e) => setObjectName(e.target.value)}
                className="w-full p-3 bg-gray-700 rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </motion.div>

            <motion.div className="mb-6" whileHover={{ scale: 1.02 }}>
              <label className="block mb-2 text-blue-300">Photo</label>
              <div className="relative">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  className="hidden"
                  id="photo-upload"
                  required
                />
                <label
                  htmlFor="photo-upload"
                  className="flex items-center justify-center w-full p-3 bg-gray-700 rounded text-white cursor-pointer hover:bg-gray-600 transition-colors"
                >
                  <FiCamera className="mr-2" />
                  {photo ? 'Changer de photo' : 'Choisir une photo'}
                </label>
              </div>
              {photoPreview && (
                <img src={photoPreview} alt="Aperçu" className="mt-4 rounded-lg max-h-48 mx-auto" />
              )}
            </motion.div>

            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-red-500 mb-4"
              >
                {error}
              </motion.div>
            )}

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full bg-blue-500 text-white py-3 px-6 rounded-lg font-semibold text-lg shadow-lg hover:bg-blue-600 transition-colors flex items-center justify-center"
              disabled={isCreating}
            >
              {isCreating ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-6 h-6 border-t-2 border-white rounded-full"
                />
              ) : (
                <>
                  <FiPlus className="mr-2" /> Créer QR Code
                </>
              )}
            </motion.button>
          </motion.form>
        )}
      </AnimatePresence>

      {qrCode && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-12 text-center"
        >
          <h2 className="text-3xl font-bold mb-4 text-blue-300">Votre QR Code</h2>
          <div className="bg-white p-4 rounded-lg inline-block">
            <QRCodeSVG value={qrCode} size={256} />
          </div>
          <p className="mt-4 text-lg">Scannez ce code pour voir la timeline de votre objet.</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleViewTimeline}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg font-semibold shadow-lg hover:bg-blue-600 transition-colors flex items-center justify-center mx-auto"
          >
            <FiEye className="mr-2" /> Voir la Timeline
          </motion.button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default HomePage;
