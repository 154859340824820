import React from 'react';
import { motion } from 'framer-motion';
import { FiCamera, FiCalendar, FiMapPin, FiTruck } from 'react-icons/fi';
import moment from 'moment';
import 'moment/locale/fr'; // Import de la localisation française
import PropTypes from 'prop-types'; // Import de PropTypes

moment.locale('fr'); // Configuration de Moment en français

// Dictionnaire pour associer les types à leurs icônes respectives
const icons = {
  'Photo': FiCamera,
  'Événement': FiCalendar,
  'Lieu': FiMapPin,
  'Voiture': FiTruck
};

// Fonction pour afficher la durée écoulée depuis la date donnée
const getTimeElapsed = (date) => {
  return moment(date).fromNow(); // Utilise Moment pour afficher la date en français
};

// Composant pour chaque élément de la timeline
const TimelineItem = ({ item, index }) => {
  const Icon = icons[item.type] || FiCamera; // Utilise l'icône correspondant ou une icône par défaut

  return (
    <motion.div
      className="timeline-item"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="timeline-content">
        <div className="timeline-icon">
          <Icon />
        </div>
        {/* Si l'URL de la photo existe, affiche l'image, sinon affiche un message */}
        {item.url ? (
          <img src={item.url} alt={item.description || 'Image'} className="timeline-image" />
        ) : (
          <p className="no-image">Aucune image disponible</p>
        )}
        <div className="timeline-info">
          <h3>{item.title || 'Sans titre'}</h3>
          <p className="timeline-time">{getTimeElapsed(item.date)}</p>
          <p className="timeline-description">{item.description || 'Pas de description'}</p>
        </div>
      </div>
    </motion.div>
  );
};

// Validation des props avec PropTypes pour le composant TimelineItem
TimelineItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired
};

// Composant pour la timeline, qui affiche tous les éléments
const Timeline = ({ photos }) => {
  return (
    <div className="timeline">
      {/* Itère sur la liste des photos et génère un élément de la timeline pour chaque entrée */}
      {photos.map((photo, index) => (
        <TimelineItem key={photo.id} item={photo} index={index} />
      ))}
    </div>
  );
};

// Validation des props avec PropTypes pour le composant Timeline
Timeline.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Timeline;
