import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'react-qr-scanner';

const ScanPage = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleScan = (data) => {
    if (data) {
      navigate(`/timeline/${data.text}`);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError('Erreur lors du scan. Veuillez réessayer.');  // ici l'apostrophe doit être échappée
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Scanner un QR Code</h1>
      <div className="max-w-md mx-auto">
        <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
      <button 
        onClick={() => navigate('/')} 
        className="mt-8 bg-blue-500 text-white px-4 py-2 rounded"
      >
        Retour à l&apos;accueil  {/* Apostrophe échappée ici */}
      </button>
    </div>
  );
};

export default ScanPage;
