import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getObject, getPhotos, addPhoto, uploadPhoto } from '../services/firebase';
import { FiCamera, FiArrowLeft, FiPlus, FiX } from 'react-icons/fi';
import Timeline from '../components/Timeline';

const TimelinePage = () => {
  const [object, setObject] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [description, setDescription] = useState('');
  const [isAddingPhoto, setIsAddingPhoto] = useState(false);
  const [error, setError] = useState('');
  const { objectId } = useParams();
  const navigate = useNavigate();

  // Référence pour scroller vers le dernier élément ajouté
  const lastPhotoRef = useRef(null);

  // Chargement des données d'un objet et de ses photos depuis Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer l'objet
        const objectData = await getObject(objectId);
        setObject(objectData);
  
        // Récupérer les photos de l'objet
        const photosData = await getPhotos(objectId);
  
        // Ajouter la photo initiale de l'objet, si elle existe
        if (objectData.photo) {
          photosData.unshift({
            id: 'initial-photo', // Identifiant unique pour la photo initiale
            url: objectData.photo, // URL de la photo
            description: 'Photo initiale', // Description par défaut
            date: objectData.createdAt // Date de création de l'objet
          });
        }
  
        // Trier les photos par date
        setPhotos(photosData.sort((a, b) => new Date(a.date) - new Date(b.date)));
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        setError("Impossible de charger les données. Veuillez réessayer.");
      }
    };
    fetchData();
  }, [objectId]);
  

  // Scroller jusqu'à la dernière photo une fois que les photos sont chargées
  useEffect(() => {
    if (photos.length > 0 && lastPhotoRef.current) {
      lastPhotoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [photos]);

  // Gestion du changement de fichier photo
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setNewPhoto(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPhotoPreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setPhotoPreview(null);
    }
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPhoto) {
      setError("Veuillez sélectionner une photo.");
      return;
    }
    setError('');
    try {
      const photoURL = await uploadPhoto(newPhoto);
      const photoData = {
        url: photoURL,
        description,
        date: new Date().toISOString(),
      };
      const photoId = await addPhoto(objectId, photoData);
      if (photoId) {
        setPhotos([...photos, { id: photoId, ...photoData }].sort((a, b) => new Date(a.date) - new Date(b.date)));
        setNewPhoto(null);
        setPhotoPreview(null);
        setDescription('');
        setIsAddingPhoto(false);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de la photo:", error);
      setError("Une erreur est survenue lors de l'ajout de l&apos;ajout de la photo. Veuillez réessayer.");
    }
  };

  if (!object) return <div className="text-center py-8">Chargement...</div>;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white p-8">

      <motion.h1
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-4xl font-bold text-center text-white mb-9"
      >
        {object?.name}
      </motion.h1>

      <Timeline photos={photos} />

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsAddingPhoto(!isAddingPhoto)}
        className="mt-8 mx-auto flex bg-green-500 text-white px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-green-600 transition-colors min-w-[200px] items-center justify-center"
      >
        {isAddingPhoto ? (
          <>
            <FiX className="icon mr-2" /> Annuler
          </>
        ) : (
          <>
            <FiPlus className="icon mr-2" />
            Ajouter une photo
          </>
        )}
      </motion.button>

      <AnimatePresence>
        {isAddingPhoto && (
          <motion.form
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            onSubmit={handleSubmit}
            className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md"
          >
            <div className="mb-4">
              <label htmlFor="photo-upload" className="block mb-2 font-semibold text-[#1E3D9A]">
                Photo
              </label>
              <input
                id="photo-upload"
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                className="hidden"
              />
              <label
                htmlFor="photo-upload"
                className="flex items-center justify-center w-full p-4 bg-white border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 transition-colors"
              >
                {photoPreview ? (
                  <img src={photoPreview} alt="Preview" className="max-h-48 rounded" />
                ) : (
                  <FiCamera className="text-4xl text-gray-900" />
                )}
              </label>
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="block mb-2 font-semibold text-[#1E3D9A]">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Décrivez votre photo..."
                className="w-full p-2 border rounded resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-600"
                rows="3"
              />
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600 transition-colors"
            >
              <FiPlus className="inline mr-2" /> Ajouter la photo
            </motion.button>
          </motion.form>
        )}
      </AnimatePresence>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => navigate('/')}
        className="mt-8 mx-auto block bg-gray-500 text-white px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-gray-600 transition-colors"
      >
        <FiArrowLeft className="inline mr-2" /> Retour à l&apos;accueil
      </motion.button>
    </div>
  );
};

export default TimelinePage;
